
import { useStore } from "vuex";
import { defineComponent, ref, onMounted, computed } from "vue";
import moment from "moment";
import { Actions } from "@/store/enums/StoreEnums";
import { useRoute, useRouter } from "vue-router";
import WalletTransactionInvoiceVue from "../wallet_transaction/WalletTransactionInvoice.vue";
export default defineComponent({
  components: {
    WalletTransactionInvoiceVue,
  },
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const route = useRoute();
    const router = useRouter();
    const idpath = ref("");
    const id = ref(true);
    const orderDetail = ref([
      {
        order_detail_id: 104006,
        order_detail_order_id: 44703,
        order_detail_product: {
          product_id: 67889,
          product_name_th: "กะเพราโคตรเผ็ด",
          product_name_en: "SO SPICY KAPROW",
          product_name_cn: null,
          product_base_price: 30,
          product_note: "",
          product_image:
            "https://storage.googleapis.com/pre-weserve/img/product/WEEWPLTXM0WF_20220511161807.jpeg",
        },
        order_detail_store: 1661,
        order_detail_product_seller_price: "30.00",
        order_detail_amount: 1,
        order_detail_status: 1,
        order_detail_total_option_price: "20.00",
        order_detail_vat_price: "0.00",
        order_detail_service_charge_price: "0.00",
        order_detail_total_product_price: "50.00",
        order_detail_check_product: 0,
        order_detail_store_id: 1661,
      },
      {
        order_detail_id: 104007,
        order_detail_order_id: 44703,
        order_detail_product: {
          product_id: 67889,
          product_name_th: "กะเพราโคตรเผ็ด",
          product_name_en: "SO SPICY KAPROW",
          product_name_cn: null,
          product_base_price: 30,
          product_note: "",
          product_image:
            "https://storage.googleapis.com/pre-weserve/img/product/WEEWPLTXM0WF_20220511161807.jpeg",
        },
        order_detail_store: 1661,
        order_detail_product_seller_price: "30.00",
        order_detail_amount: 1,
        order_detail_status: 1,
        order_detail_total_option_price: "20.00",
        order_detail_vat_price: "0.00",
        order_detail_service_charge_price: "0.00",
        order_detail_total_product_price: "50.00",
        order_detail_check_product: 0,
        order_detail_store_id: 1661,
      },
    ]);
    function extractArrayString(array) {
      let text = "";
      array.forEach(function (e) {
        text += e.order_point_lat + "," + e.order_point_long + "/";
      });

      return text;
    }

    const walletTransactionDetail = computed(() => {
      return store.state.WalletModule.walletTransactionDetail;
    });
    const walletTransactionInvoice = computed(() => {
      return store.state.WalletModule.walletTransactionInvoice;
    });

    async function init() {
      isLoading.value = true;
      await store.dispatch(
        `WalletModule/${Actions.FETCH_WALLET_TRANSACTION_BY_ID}`,
        {
          transactionId: route.params.transaction_id,
          orderCode: route.params.order_code,
        }
      );
      isLoading.value = false;
    }

    onMounted(() => {
      console.log(walletTransactionDetail.value.order.order_code);
      const idpath = route.params.order_code;

      if (idpath == "Invoice") {
        id.value = false;
      }
      if (walletTransactionDetail.value.order.order_code == undefined) init();
    });

    return {
      moment,
      isLoading,
      router,
      route,
      extractArrayString,
      walletTransactionDetail,
      orderDetail,
      walletTransactionInvoice,
      idpath,
      id,
      init,
      //idbill,
    };
  },
});
