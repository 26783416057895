
import { useStore } from "vuex";
import { defineComponent, ref, onMounted, computed } from "vue";
import moment from "moment";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "WalletTransactionInvoiceVue",
  // props: {
  //   walletTransactionInvoice: [],
  // },
  data() {
    return {
      databill: {},
    };
  },
  // watch: {
  //   walletTransactionInvoice: function () {
  //     console.log("55555555");
  //   },
  // },
  mounted() {
    this.databill = this.$route.params.transactionId;
    console.log(this.databill);
  },
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const router = useRouter();
    const credit = ref({});
    const check = ref(false);
    const slidepage = ref(true);
    const slidepaynow = ref(false);
    const isLoadingPage = ref(true);
    const data = ref({});
    // const transactionId = ref([]);
    async function Popuppage(item) {
      check.value = true;
      credit.value = item;
    }
    // async function toPDF() {
    //   html2pdf(document.getElementById("element-to-convert"), {
    //     margin: 1,
    //     filename: "i-was-html.pdf",
    //   });
    // }
    async function Done() {
      check.value = false;
    }
    async function setTime() {
      setTimeout(function () {
        isLoadingPage.value = false;
        slidepaynow.value = true;
      }, 1200);
    }
    async function init() {
      await store.dispatch(
        "WalletModule/" + Actions.FETCH_WALLET_TRANSACTION_BY_INVOICE
      );
    }

    onMounted(() => {
      init();
      // if (walletTransactionInvoice.bill_status == `2`) {
      //   console.log("gfgg");
      // }
    });
    const walletTransactionInvoice = computed(() => {
      return store.state.WalletModule.walletTransactionInvoice; //เข้าไปที่ state.ตัวแปรข้อมูล
    });
    return {
      moment,
      isLoading,
      router,
      Popuppage, //ฟังค์ชัน event เมื่อ click
      check,
      credit,
      slidepage,
      slidepaynow,
      Done,
      isLoadingPage,
      setTime,
      data,
      walletTransactionInvoice,
      //toPDF,
    };
  },
});
